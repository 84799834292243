var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Masthead',{attrs:{"schema":_vm.realSchema,"resource":_vm.resource,"is-creatable":false,"type-display":_vm.typeDisplay},scopedSlots:_vm._u([{key:"typeDescription",fn:function(){return [_c('TypeDescription',{attrs:{"resource":_vm.hResource}})]},proxy:true}])},[_vm._v(" "),(_vm.canCreateCluster)?_c('template',{slot:"extraActions"},[_c('n-link',{staticClass:"btn role-primary",attrs:{"to":_vm.importLocation}},[_vm._v("\n        "+_vm._s(_vm.t('cluster.importAction'))+"\n      ")])],1):_vm._e()],2),_vm._v(" "),(_vm.rows && _vm.rows.length)?_c('ResourceTable',{attrs:{"schema":_vm.schema,"rows":_vm.rows,"sub-rows":true,"is-creatable":true,"namespaced":false},scopedSlots:_vm._u([{key:"col:name",fn:function(ref){
var row = ref.row;
return [_c('td',[_c('span',[(row.isReady)?_c('n-link',{attrs:{"to":{
              name: ("c-cluster-" + _vm.VIRTUAL),
              params: {
                cluster: row.status.clusterName,
              }
            }}},[_vm._v("\n            "+_vm._s(row.nameDisplay)+"\n          ")]):_c('span',[_vm._v("\n            "+_vm._s(row.nameDisplay)+"\n          ")])],1)])]}},{key:"cell:harvester",fn:function(ref){
            var row = ref.row;
return [_c('n-link',{staticClass:"btn btn-sm role-primary",attrs:{"to":row.detailLocation}},[_vm._v("\n        "+_vm._s(_vm.t('harvester.virtualizationManagement.manage'))+"\n      ")])]}}],null,false,2491500664)}):_c('div',[_c('div',{staticClass:"no-clusters"},[_vm._v("\n      "+_vm._s(_vm.t('harvester.manager.cluster.none'))+"\n    ")]),_vm._v(" "),_c('hr',{staticClass:"info-section"}),_vm._v(" "),_c('div',{staticClass:"logo"},[_c('BrandImage',{attrs:{"file-name":"harvester.png","height":"64"}})],1),_vm._v(" "),_c('div',{staticClass:"tagline"},[_c('div',[_vm._v(_vm._s(_vm.t('harvester.manager.cluster.description')))])]),_vm._v(" "),_c('div',{staticClass:"tagline sub-tagline"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.t('harvester.manager.cluster.learnMore', {}, true))}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }