import { render, staticRenderFns } from "./rbac.authorization.k8s.io.clusterrole.vue?vue&type=template&id=39eed2b6&"
import script from "./rbac.authorization.k8s.io.clusterrole.vue?vue&type=script&lang=js&"
export * from "./rbac.authorization.k8s.io.clusterrole.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports