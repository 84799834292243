import { render, staticRenderFns } from "./ClusterBadge.vue?vue&type=template&id=34a6d7f2&scoped=true&"
import script from "./ClusterBadge.vue?vue&type=script&lang=js&"
export * from "./ClusterBadge.vue?vue&type=script&lang=js&"
import style0 from "./ClusterBadge.vue?vue&type=style&index=0&id=34a6d7f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a6d7f2",
  null
  
)

export default component.exports