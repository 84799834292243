
import Vue from 'vue';
import { nlToBr } from '@/utils/string';
import { stringify } from '@/utils/error';

export default Vue.extend({
  props: {
    color: {
      type:    String,
      default: 'secondary'
    },
    label: {
      type:    [String, Error, Object],
      default: null,
    },
    labelKey: {
      type:    String,
      default: null,
    },
    closable: {
      type:    Boolean,
      default: false,
    }
  },
  computed: {
    /**
     * Return message text as label
     */
    messageLabel(): string | void {
      return !(typeof this.label === 'string') ? stringify(this.label) : undefined;
    }
  },
  methods: { nlToBr },
});
